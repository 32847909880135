<script setup lang="ts">
    import FormField from '@/components/form-components/FormField.vue';
    import { SalutationType } from '@/types/Salutation';
    import { hasValidationError } from '@/util/hasValidationError';
    import { type CustomerDto } from '@containex/portal-backend-dto';
    import { toTypedSchema } from '@vee-validate/zod';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import { useForm } from 'vee-validate';
    import { useI18n } from 'vue-i18n';
    import { object, string } from 'zod';
    import type { CustomerUpdateData } from '../model/customer-update';
    import RadioButtonField from '@/components/form-components/RadioButtonField.vue';

    const props = defineProps<{
        customer: CustomerDto;
    }>();

    const emits = defineEmits<{
        close: [];
        submit: [CustomerUpdateData];
    }>();

    const { t } = useI18n();

    const schema = object({
        jobTitle: string()
            .trim()
            .optional()
            .default(props.customer.job_title ?? ''),
        firstName: string().trim().min(1).default(props.customer.first_name),
        lastName: string().trim().min(1).default(props.customer.last_name),
        phoneBusiness: string().trim().min(1).default(props.customer.phone_business),
        phoneMobile: string().trim().min(1).default(props.customer.phone_mobile),
        salutation: string().min(1).default(props.customer.salutation),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [jobTitle] = defineField('jobTitle');
    const [firstName] = defineField('firstName');
    const [lastName] = defineField('lastName');
    const [phoneBusiness] = defineField('phoneBusiness');
    const [phoneMobile] = defineField('phoneMobile');
    const [salutation] = defineField('salutation');

    const onSubmit = handleSubmit((values) => {
        emits('submit', {
            email: props.customer.email,
            last_name: values.lastName,
            first_name: values.firstName,
            salutation: values.salutation,
            phone_business: values.phoneBusiness,
            phone_mobile: values.phoneMobile,
            job_title: values.jobTitle,
            roles: props.customer.roles,
        });
    });
</script>

<template>
    <Dialog :visible="true" modal @update:visible="emits('close')">
        <template #header>
            <div class="dialog-header-typography">{{ t('ACCOUNT.EDIT_PERSONAL_DATA') }}</div>
        </template>
        <form class="add-user-container">
            <div class="radio-fields">
                <RadioButtonField
                    v-model="salutation"
                    :value="SalutationType.MALE"
                    :label="t('FORM.SALUTATION_MALE')"
                    :error-message="errors.salutation"
                />
                <RadioButtonField
                    v-model="salutation"
                    :value="SalutationType.FEMALE"
                    :label="t('FORM.SALUTATION_FEMALE')"
                    :error-message="errors.salutation"
                />
            </div>
            <FormField :label="t('FORM.FIRST_NAME')" :error-message="errors.firstName">
                <InputText v-model="firstName" class="full-width" :invalid="hasValidationError(errors.firstName)" />
            </FormField>
            <FormField :label="t('FORM.LAST_NAME')" :error-message="errors.lastName">
                <InputText v-model="lastName" class="full-width" :invalid="hasValidationError(errors.lastName)" />
            </FormField>
            <FormField :label="t('FORM.JOB_TITLE')" :error-message="errors.jobTitle">
                <InputText v-model="jobTitle" class="full-width" :invalid="hasValidationError(errors.jobTitle)" />
            </FormField>
            <FormField :label="t('FORM.PHONE_BUSINESS')" :error-message="errors.phoneBusiness">
                <InputText
                    v-model="phoneBusiness"
                    class="full-width"
                    :invalid="hasValidationError(errors.phoneBusiness)"
                />
            </FormField>
            <p class="small-secondary-text">{{ t('FORM.INCLUSIVE_COUNTRY_CODE') }}</p>
            <FormField :label="t('FORM.PHONE_MOBILE')" :error-message="errors.phoneMobile">
                <InputText v-model="phoneMobile" class="full-width" :invalid="hasValidationError(errors.phoneMobile)" />
            </FormField>
            <br />
        </form>
        <template #footer>
            <Button outlined type="button" :label="t('COMMON.CANCEL')" @click="emits('close')" />
            <Button type="submit" :label="t('COMMON.SAVE')" @click="onSubmit" />
        </template>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .add-user-container {
        background: main.$color-white;
        display: flex;
        flex-direction: column;
        width: min(60ch, 90vw);
    }

    .radio-fields {
        display: flex;
        gap: 2ch;
    }

    .small-secondary-text {
        font-size: main.$font-size-2;
        color: main.$color-secondary;
        margin: 0;
    }
</style>
