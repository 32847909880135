<script setup lang="ts">
    import { object, string } from 'zod';
    import { useForm } from 'vee-validate';
    import { toTypedSchema } from '@vee-validate/zod';
    import FormField from '@/components/form-components/FormField.vue';
    import type { AddressDto } from '@containex/portal-backend-dto';
    import { hasValidationError } from '@/util/hasValidationError';
    import Button from 'primevue/button';
    import Dialog from 'primevue/dialog';
    import InputText from 'primevue/inputtext';
    import { useLanguage } from '@/composables/useLanguage';
    import { useI18n } from 'vue-i18n';

    const props = defineProps<{
        address: AddressDto;
        title: string;
    }>();

    const emits = defineEmits<{
        submit: [addressDto: AddressDto];
        close: [];
    }>();

    const { t } = useI18n();
    const { countryCodeToLocalName } = useLanguage();

    const schema = object({
        company: string()
            .trim()
            .min(1)
            .default(props.address.company ?? ''),
        city: string()
            .trim()
            .min(1)
            .default(props.address.city ?? ''),
        postalCode: string()
            .trim()
            .min(1)
            .default(props.address.postalCode ?? ''),
        address1: string()
            .trim()
            .min(1)
            .default(props.address.address1 ?? ''),
        countryCode: string()
            .min(1)
            .default(props.address.countryCode ?? ''),
    });

    const { handleSubmit, errors, defineField } = useForm({
        validationSchema: toTypedSchema(schema),
    });

    const [company] = defineField('company');
    const [city] = defineField('city');
    const [address1] = defineField('address1');
    const [postalCode] = defineField('postalCode');
    const [countryCode] = defineField('countryCode');

    const onSubmit = handleSubmit((values) => {
        emits('submit', {
            company: values.company,
            city: values.city,
            address1: values.address1,
            postalCode: values.postalCode,
            countryCode: values.countryCode,
        });
    });
</script>

<template>
    <Dialog :visible="true" modal @update:visible="emits('close')">
        <template #header
            ><div class="dialog-header-typography">{{ title }}</div></template
        >
        <form class="flex-container" @submit.prevent="onSubmit">
            <FormField :label="t('FORM.COMPANY')" :error-message="errors.company">
                <InputText v-model="company" class="full-width" :invalid="hasValidationError(errors.company)" />
            </FormField>
            <FormField :label="t('ADDRESS.ADDRESS')" :error-message="errors.address1">
                <InputText v-model="address1" class="full-width" :invalid="hasValidationError(errors.address1)" />
            </FormField>
            <div class="row">
                <FormField class="zip" :label="t('FORM.POSTAL_CODE')" :error-message="errors.postalCode">
                    <InputText
                        v-model="postalCode"
                        class="full-width"
                        :invalid="hasValidationError(errors.postalCode)"
                    />
                </FormField>
                <FormField class="city" :label="t('FORM.CITY')" :error-message="errors.city">
                    <InputText v-model="city" class="full-width" :invalid="hasValidationError(errors.city)" />
                </FormField>
            </div>
            <FormField :label="t('FORM.COUNTRY')">
                <InputText :model-value="countryCodeToLocalName(countryCode ?? '')" disabled class="full-width" />
            </FormField>
        </form>
        <br />
        <template #footer>
            <Button outlined type="button" :label="t('COMMON.CANCEL')" @click="emits('close')" />
            <Button type="submit" :label="t('COMMON.SAVE')" @click="onSubmit" />
        </template>
    </Dialog>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .flex-container {
        display: flex;
        flex-direction: column;
        width: min(60ch, 90vw);
        container-type: inline-size;
        container-name: address-form-dialog-content;
    }

    .row {
        display: flex;
        flex-direction: column;
        column-gap: main.$spacing-5;
    }

    @container address-form-dialog-content (min-width: 500px) {
        .row {
            flex-direction: row;

            .zip {
                width: 25%;
            }

            .city {
                width: 75%;
            }
        }
    }
</style>
